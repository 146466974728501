import React from "react";
import { Link } from "gatsby";
import Container from "../../../utils/Container";
import { footerContents } from "../../../constants/footer-us";
import { getCopyright, getSocialMediaLink } from "../../../utils";

const Footer = () => {
  return (
    <footer>
      <Container className=" max-w-[125rem] mx-aut0 my-0">
        <div className=" pt-10 lg:flex justify-start sm:block">
          <div className="sm:w-full md:w-2/6 pr-2">
            <div className=" h-14 w-3/5 ">
              <img src="https://entomo.co/wp-content/uploads/2021/02/001_Entomo_Logo_Colour.svg" alt="logo" />
            </div>
          </div>
          <div className="grid md:grid-cols-3 sm:grid-cols-1 justify-between gap-1 lg:w-8/12 lg:mt-6 mt-10">
            {footerContents.map((footerContents) => {
              return (
                <div className="text-[#333333] text-base mb-1 font-extrabold " key={footerContents.id}>
                  <h3>{footerContents.title}</h3>
                  <div className="text-[#4d4d4d] text-sm font-normal">
                    {footerContents.contents.map((content) => {
                      return (
                        <Link to={content.link} key={content.text}>
                          <p className=" p-1 break-normal leading-6">{content.text}</p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="text-[#333333] text-base mb-1 font-extrabold ">
              <h3>United States</h3>
              <p className="text-[#4d4d4d] text-sm font-normal">
                2700 Post Oak Blvd,
                <br />
                21st Floor,
                <br />
                Houston, TX 77056
                <br />
                <a href="tel:+1 (800) 947 8211">Tel:+1 (800) 947 8211</a>
              </p>
            </div>
          </div>
        </div>
        {getSocialMediaLink(["facebook", "twitter", "linkedin", "youtube", "instagram"])}
      </Container>
      {getCopyright()}
    </footer>
  );
};

export default Footer;
