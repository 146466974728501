import React from "react";
import NavMenuRequestDemo from "../components/NavMenuRequestDemo";

import imageSolutionsMenu from "../images/solutions_menu.png";
import imageMenuResource from "../images/menu_resource.png";
import imageAboutMenu from "../images/about_menu.png";
import imageWorldWide from "../images/worldwide.png";
import imageOnlineCoRoundCorder from "../images/imageonline-co-roundcorner.png";

export const menuItems = [
  {
    name: "solutions",
    component: "solutions",
    title: "solutions",
    description: "prepare your organization for the future, you wish to create",
    url: "/us/solutions/",
    img: imageSolutionsMenu,
    sub: [
      {
        name: "digital marketing insights",
        description: "measure and drive performance, everyday",
        url: "/us/digital-marketing-insights/",
      },
      {
        name: "performance action management",
        description: "drive engagement within and across teams",
        url: "/us/performance-action-management/",
      },
      {
        name: "talent experience enablement",
        description: "nudge with Insights personalized to individual and team goals",
        url: "/us/talent-experience-enablement/",
      },
      {
        name: "careers tomorrow",
        description: "manage distributed and remote teams",
        url: "/us/careers-tomorrow/",
      },
    ],
  },
  {
    name: "resources",
    component: "resources",
    title: "inspiration",
    description: "industry insights, inspiring stories, innovative ideas – everything in one place.",
    img: imageMenuResource,
    sub: [
      {
        name: "blog",
        url: "/us/resources/blog/",
      },
      {
        name: "e books & whitepapers",
        url: "/us/resources/whitepaper-e-book/",
      },
      {
        name: "success stories",
        url: "/us/resources/success-stories/",
      },
      {
        name: "entomo community",
        url: "/us/entomo-community/",
      },
    ],
  },
  {
    name: "about",
    component: "about",
    title: "why entomo",
    description: "want to know more about entomo ? You have come to the right place.",
    img: imageAboutMenu,
    sub: [
      {
        name: "why entomo",
        url: "/us/about/why-entomo/",
      },
      {
        name: "our team",
        url: "/us/about/our-team/",
      },
      {
        name: "news & press releases",
        url: "/us/news-press-releases/",
      },
    ],
  },
  {
    name: "contact",
    component: "contact",
    url: "/us/contact/",
  },
  {
    name: "schedule demo",
    component: <NavMenuRequestDemo text="schedule demo" url={"/us/request-demo/"} />,
  },
  {
    name: "regional presense",
    component: <img src={imageWorldWide} alt="worldwide" className="h-6 lg:h-7" />,
    title: "regional presence",
    description: "regional offerings are tailor made to incorporate region wise requirements",
    img: imageOnlineCoRoundCorder,
    sub: [
      {
        name: "Middle East & Africa",
        url: "/en-mea/",
      },
      {
        name: "Indonesia",
        url: "/id/",
      },
      {
        name: "Japanese",
        url: "/jp/",
      },
      {
        name: "Global",
        url: "/",
      },
    ],
  },
];
