const footerContents = [
  {
    title: "solutions",
    contents: [
      {
        text: "Talent Experience Enablement",
        link: "https://entomo.co/us/talent-experience-enablement/",
      },
      {
        text: "Performance Action Management",
        link: "https://entomo.co/us/performance-action-management/",
      },
      {
        text: "Digital Marketing Insights",
        link: "https://entomo.co/us/digital-marketing-insights/",
      },
      {
        text: "Careers Tomorrow",
        link: "https://entomo.co/us/careers-tomorrow/",
      },
    ],
  },
  {
    title: "inspiration",
    contents: [
      {
        text: "blogs",
        link: "https://entomo.co/us/resources/blog/",
      },
      {
        text: "events& webinars",
        link: "https://entomo.co/us/resources/events-webinar/",
      },
      {
        text: "success stories",
        link: "https://entomo.co/us/resources/success-stories/",
      },
      {
        text: "news & press releases",
        link: "https://entomo.co/us/news-press-releases/",
      },
      {
        text: "e books & whitepapers",
        link: "https://entomo.co/us/resources/whitepaper-e-book/",
      },
    ],
  },
];

export { footerContents };
