import * as React from "react";
import Layout from "../../containers/us/layout";
import Seo from "../../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
  </Layout>
);

export default IndexPage;
